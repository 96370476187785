<template>
    <!-- edit profile dialog -->
    <v-dialog
          v-model="isPaperQuestionnaireDialogOpen"
          max-width="720px"
          @click:outside="$emit('update:is-paper-questionnaire-dialog-open',false)"
        >
          <v-card class="py-8">
            <v-card-title class="justify-center text-h5 px-5">
              Export Questionnaire to Word.
            </v-card-title>
            <v-card-text class="text-center mt-n2 px-5">
              Please select the language you wish to export.
            </v-card-text>
            <v-card-text class="d-flex align-center flex-wrap flex-sm-nowrap mt-5 px-15">
              <v-form
                  ref="exportForm"
              >
                <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <v-autocomplete
                        v-model="language"
                        :items="languageOptions"
                        item-text="name"
                        item-value="id"
                        label="Export Language"
                        hide-detail
                        dense
                        outlined
                        return-object
                        multiple
                        placeholder="Select Language"
                        :rules="[validators.required]"
                    >
                        
                    </v-autocomplete>
                    
                    </v-col>
                   
                </v-row>
              </v-form>
            </v-card-text>
            
            <v-divider class="mt-0"></v-divider>
            <v-card-text class="px-15 pt-8">
              <v-btn
                    color="primary"
                    class="me-3"
                    type="submit"
                    :loading="loading"
                    @click.prevent="submit"

                  >
                    Export
                  </v-btn>
    
                  <v-btn
                    outlined
                    color="secondary"
                    @click.prevent="closeWindow"
                  >
                    Cancel
                  </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
  </template>
    <script>
    import { ref, watch, getCurrentInstance } from '@vue/composition-api'
    // import ProjectIntercoList from '../project-interco-list/ProjectIntercoList.vue'
    import {
      required,
    } from '@core/utils/validation'
    
    export default {
      props: {
        isPaperQuestionnaireDialogOpen: {
          type: Boolean,
          required: true,
        },
        languageOptions: {
          type: Array,
          required: true
        },
        loading: {
          type: Boolean,
          default: false,
        }
      },
    
      setup(props, { emit }) {

        const vm = getCurrentInstance().proxy
        const language = ref([])

        const submit = () => {

          
          // If step is valid proceed to the next step
          const isStepValid = vm.$refs['exportForm'].validate()
          if (isStepValid){
            const lang = JSON.parse(JSON.stringify(language.value))
            emit('export', lang)
          }
        }

        const closeWindow = () => {
          emit('update:is-paper-questionnaire-dialog-open',false); 
        }

        watch(
          () => props.loading,
          () => {
            if (!props.loading) emit('update:is-paper-questionnaire-dialog-open',false)
          }
        )

    
        return {
          language,
          submit,
          closeWindow,
          validators: {
            required
          }
        }
      },
    }
    </script>