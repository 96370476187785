
import { Mark } from 'tiptap'
import { updateMark, toggleMark } from 'tiptap-commands'

class Interviewer extends Mark  {
    get name() 
    {
        return 'interviewer'
    }

    get schema() 
    {
        return {
            attrs: {
                "interviewer-id": {
                    default: null
                },
                color: {
                    default: '#FF0000'
                },
                fontSize: {
                    default: ''
                },
                fontFamily: {
                    default: ''
                },
                backgroundColor: {
                    default: ''
                },
                lineHeight: {
                    default: ''
                }
            },
            inclusive: true,
            parseDOM: [
                {
                    tag: 'interviewer',
                    getAttrs: dom => ({
                        
                    }),
                },
            ],
            toDOM: mark => ['interviewer', {
                class:'enq'
            }, 0],
        }
    }
    
    keys ({ type }) {
        return {
        'Mod-f': updateMark(type)
        }
    }
    commands({ type, schema }) 
    {
        return attrs => 
        {
            return toggleMark(type);
        }
    }

}

export default Interviewer