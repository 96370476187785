<template>
    <v-dialog
          v-model="isPipingVarDialogOpen"
          max-width="720px"
            persistent
        >
          <v-card class="py-2">
            <v-card-title class="justify-center text-h5 px-5">
              Please enter the piping label for your question.
            </v-card-title>
            <v-card-text class="d-flex align-center flex-wrap flex-sm-nowrap mt-5 px-15">
              <v-form
                  ref="pipingVarForm"
              >
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                    >
                        <span>Specify piping name for {{ localPipe.question }}</span>
                        <v-btn
                            text
                            icon
                            small
                            color="primary"
                            @click="evenCellSplit"
                        >
                        <v-icon 
                            color="primary"
                            
                        >{{ icons.mdiSetSplit }}</v-icon>
                        </v-btn>
                        <v-text-field
                            v-for="(value, index) in localPipe.value" :key="index"
                            v-model="localPipe.value[index]"
                            :label="`Phase ${index+1}`"
                            outlined
                            dense
                            class="pb-2"
                            hide-details
                            :rules="[validators.required]"
                        ></v-text-field>
                    </v-col>
                   
                </v-row>
              </v-form>
            </v-card-text>
            
            <v-divider class="mt-0"></v-divider>
            <v-card-text class="px-15 pt-8">
              <v-btn
                    color="primary"
                    class="me-3"
                    type="submit"
                    @click.prevent="submit"

                  >
                    Submit
                  </v-btn>
    
                  <!--<v-btn
                    outlined
                    color="secondary"
                    @click.prevent="closeDialog"
                  >
                    Cancel
                  </v-btn>-->
            </v-card-text>
          </v-card>
        </v-dialog>
</template>

<script>
import { ref, watch, getCurrentInstance, onMounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import {
    mdiSetSplit,
} from '@mdi/js'

export default ({
    props: {
        isPipingVarDialogOpen: {
          type: Boolean,
          required: true,
        },
        pipe: {
            type: Object,
            required: false,
            default: () => {
                return {
                    phase: 4,
                    question: "findability",
                    id: "finda",
                    value: [],
                    sectionIndex: 0,
                    elementIndex:0
                }
            }
        }
    },
    setup(props, { emit }) {

        const vm = getCurrentInstance().proxy
        const localPipe = ref({})
        const pipingVarForm = ref(null)

        const init = () => {
            localPipe.value = JSON.parse(JSON.stringify(props.pipe))
            localPipe.value.value = []
            
            for (let i = 0; i < localPipe.value.phase; i++){
                localPipe.value.value[i] = null
            }
        }

        const evenCellSplit = () => {
            const value = localPipe.value.value[0]
            localPipe.value.value = []
            for (let i = 0; i < localPipe.value.phase; i++){
                localPipe.value.value[i] = value
            }

        }

        const closeDialog = () => {
            init()
            pipingVarForm.value.resetValidation()
            emit('update:is-piping-var-dialog-open',false)
        }

        const submit = () => {

            const isFormValid = pipingVarForm.value.validate()
            if (isFormValid){
                const pipe = JSON.parse(JSON.stringify(localPipe.value))
                emit('update', pipe)
            }
        }

        onMounted( () => {
            init()
        })

        watch(
            () => props.pipe,
            () => {
                init()
            }
        )

        return {
            localPipe,
            pipingVarForm,

            evenCellSplit,
            closeDialog,
            submit,

            icons: {
                mdiSetSplit
            },
            
            validators: {
                required
            }
        }
        
    },
})
</script>
