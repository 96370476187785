import { Node } from "tiptap";
import { replaceText, updateMark, toggleMark  } from "tiptap-commands";

export default class Variable extends Node {
  get name() {
    return "variable";
  }

  get defaultOptions() {
    return {
      matcher: {
        char: "{",
        allowSpaces: false,
        startOfLine: false
      },
      variableClass: "variable",
      suggestionClass: "variable-suggestion",
      endTag:{
        char: "}"
      }
    };
  }

  get schema() {
    return {
      attrs: {
        class: {
          default: 'variable',
        },
        label: {}
      },
      group: "inline",
      inline: true,
      selectable: false,
      atom: true,
      toDOM: node => [
        "span",
        {
          class: this.options.variableClass
        },
        `${this.options.matcher.char}${node.attrs.label}${this.options.endTag.char}`
      ],
      parseDOM: [
        {
          tag: "span[class=\"variable\"]",
          getAttrs: dom => {
              const label = dom.innerText
              .split(this.options.matcher.char)
              .join("")
              .split(this.options.endTag.char)
              .join("");
              return { label };
          }
        }
      ]
    };
  }

  commands({ schema }) {
    return attrs => replaceText(null, schema.nodes[this.name], attrs);
  }

  
}