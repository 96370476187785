<template>
    <v-dialog
        v-model="isDialogOpen"
        width="500"
        @click:outside="$emit('update:is-dialog-open',false)"
    >
        <v-card >
            <v-card-title class="justify-left">
                {{`Please list the items.\nOne item per line.`}}
            </v-card-title>

            <v-card-text class="mt-5">
                <v-textarea
                    v-model="localItemList"
                    outlined
                    :placeholder="`Item 1\nItem 2\nItem 3`"
                >

                </v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="updateItemList"
                >
                    Apply
                </v-btn>
                <v-btn
                    text
                    @click="$emit('close')"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default ({
    props: {
        isDialogOpen: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        itemList: {
            type: String,
            default: ""
        },
        defaultLang: {
            type: Object,
            required:true
        },
        state: {
            type: String,
            default: ""
        },
    },
    setup(props, {emit}) {
        const localItemList = ref("")
        const localDefaultLang = ref({})
        localDefaultLang.value = JSON.parse(JSON.stringify(props.defaultLang))

        const resetLocalItemList = () => {
            localItemList.value = JSON.parse(JSON.stringify(props.itemList))
        }

        const updateItemList = () => {
            const splitList = localItemList.value.split("\n")
            // var index = splitList.length

            let codeList = {}
            splitList.forEach(str => {
                let strParts = str.split("|")
                if(strParts.length > 1) codeList[strParts[0]] = 0
            })
            
            var localList = []
            localList.push(splitList.filter(n => n).map( (str) => {
                let strParts = str.split("|");
                let obj = {}
                let dupCode = false
                var index = 1
                    
                let code = strParts.length === 1 ? `${props.state === 'columns' ? 'c' : 'r'}${index}` :  strParts[0]
                codeList[code] += 1
                if (codeList[code] > 1){
                    
                    while(!dupCode){
                        if (codeList[code] === undefined){
                            codeList[code] = 1
                            dupCode = true
                        } else {
                            index++
                            code =  `${props.state === 'columns' ? 'c' : 'r'}${index}`
                        }
                    }
                }
                else {
                    codeList[code] = 1
                    index++
                }
                

                
                obj.value = code
                obj.text = {}
                obj.text[localDefaultLang.value.code] = strParts.length === 1 ? strParts[0].trim() : strParts[1].trim()

                return obj;
            }))
            
            emit('update-list', localList[0])
            emit('close')
        }

        watch(
            () => props.itemList,
            () => {
                resetLocalItemList()
            }
        )

        watch(
            () => props.defaultLang,
            () => {
                localDefaultLang.value = JSON.parse(JSON.stringify(props.defaultLang))
            }
        )


        return {
            localItemList,
            updateItemList
        }
    },
})
</script>
