<template>
    <slide-group
        v-model="localCurrentStep"
        class="app-stepper"
        show-arrows
        :direction="direction"
        :class="`app-stepper-${align}`"
    >
        <v-slide-item
        v-for="(item, index) in items"
        :key="item.title"
        :value="index"
        >
        <div
            class="cursor-pointer mx-1"
            :class="[
            (!isActiveStepValid && (isValidationEnabled)) && 'stepper-steps-invalid',
            activeOrCompletedStepsClasses(index),
            ]"
            @click="!isValidationEnabled && stepClick(index)"
        >
            <!-- SECTION stepper step with icon -->
            <template v-if="item.icon">
            <div class="stepper-icon-step text-high-emphasis d-flex align-center gap-2">
                <!-- 👉 icon and title -->
                <div
                class="d-flex align-center gap-2 step-wrapper"
                :class="[direction === 'horizontal' && 'flex-column']"
                >
                <div class="stepper-icon">
                    <v-icon
                    :size="item.size || iconSize"
                    >
                      {{ item.icon }}
                    </v-icon>
                </div>

                <div>
                    <p class="stepper-title text-base mb-0">
                    {{ item.title }}
                    </p>
                    <span
                    v-if="item.subtitle"
                    class="stepper-subtitle text-sm"
                    >{{ item.subtitle }}</span>
                </div>
                </div>

                <!-- 👉 append chevron -->
                <v-icon
                v-if="isHorizontalAndNotLastStep(index)"
                class="flip-in-rtl stepper-chevron-indicator mx-6"
                size="18"
                icon="mdi-chevron-right"
                />
            </div>
            </template>
            <!-- !SECTION  -->

            <!-- SECTION stepper step without icon -->
            <template v-else>
            <div class="d-flex align-center gap-x-2">
                <div class="d-flex align-center gap-2">
                <div
                    class="d-flex align-center justify-center"
                    style="block-size: 24px; inline-size: 24px;"
                >
                    <!-- 👉 custom circle icon -->
                    <template v-if="index >= localCurrentStep">
                    <div
                        v-if="(!isValidationEnabled || isActiveStepValid || index !== localCurrentStep)"
                        class="stepper-step-indicator"
                    />

                    <v-icon
                        v-else
                        size="24"
                        color="error"
                    >{{ icons.mdiAlertCircleOutline }}</v-icon>
                    </template>

                    <!-- 👉 step completed icon -->

                    <v-icon
                    v-else
                    class="stepper-step-icon"
                    size="24"
                    >{{ icons.mdiCheckCircleOutline }}</v-icon>
                </div>

                <!-- 👉 Step Number -->
                <h4 class="text-h4 step-number">
                    {{ (index + 1).toString().padStart(2, '0') }}
                </h4>
                </div>

                <!-- 👉 title and subtitle -->
                <div style="line-height: 0;">
                <h6 class="text-sm font-weight-medium step-title">
                    {{ item.title }}
                </h6>

                <span
                    v-if="item.subtitle"
                    class="text-xs step-subtitle"
                >
                    {{ item.subtitle }}
                </span>
                </div>

                <!-- 👉 stepper step line -->
                <div
                v-if="isHorizontalAndNotLastStep(index)"
                class="stepper-step-line"
                />
            </div>
            </template>
            <!-- !SECTION  -->
        </div>
    </v-slide-item>
    </slide-group>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import SlideGroup from './SlideGroup.vue'
import {
  mdiAlertCircleOutline,
  mdiCheckCircleOutline 
} from '@mdi/js'

export default ({
    components: {
      SlideGroup
    },
    props: {
        items: {
          type: Array,
          required: true,
      },
      currentStep: {
          type: Number,
          required: true,
          default: 0,
      },
      direction: {
          type: String,
          required: false,
          default: 'horizontal',
      },
      iconSize: {
          type: [
          String,
          Number,
          ],
          required: false,
          default: 52,
      },
      isActiveStepValid: {
          type: Boolean,
          required: false,
          default: undefined,
      },
      align: {
          type: String,
          required: false,
          default: 'center',
      },
    },
    setup(props, { emit }) {

        const localCurrentStep = ref(props.currentStep || 0)

        
        const activeOrCompletedStepsClasses = computed(() => index => index < localCurrentStep.value ? 'stepper-steps-completed' : index === localCurrentStep.value ? 'stepper-steps-active' : '')
        const isHorizontalAndNotLastStep = computed(() => index => props.direction === 'horizontal' && props.items.length - 1 !== index)

        // check if validation is enabled
        const isValidationEnabled = computed(() => {
            return props.isActiveStepValid !== undefined
        })

        const stepClick = (index) => {
          emit('update:current-step', index)
        }

        watch(
          () => props.currentStep,  
          () => {
            
            if (props.currentStep !== undefined && props.currentStep < props.items.length && props.currentStep >= 0)
              localCurrentStep.value = props.currentStep
            emit('update:currentStep', localCurrentStep.value)
          }
        )
        

        return {
            localCurrentStep,
            stepClick,
            activeOrCompletedStepsClasses,
            isHorizontalAndNotLastStep,
            isValidationEnabled,

            icons: {
              mdiAlertCircleOutline,
              mdiCheckCircleOutline
            }
        }
        
    },
})
</script>
<style lang="scss">
.app-stepper {
  // 👉 stepper step with bg color
  &.stepper-icon-step-bg {
    .stepper-icon-step {
      .step-wrapper {
        flex-direction: row !important;
      }

      .stepper-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.3125rem;
        background-color: rgba(58,53,65, 0.12);
        block-size: 2.5rem;
        inline-size: 2.5rem;
        margin-inline-end: 0.3rem;
        .v-icon {
          color: rgba(58,53,65, 0.87);
        }
      }

      .stepper-title,
      .stepper-subtitle {
        font-weight: 400 !important;
        line-height: normal;
      }

      .stepper-title {
        color: rgba(58,53,65, 0.60);
        font-size: 0.875rem;
      }

      .stepper-subtitle {
        color: rgba(58,53,65, 0.38);
        font-size: 0.75rem;
      }
    }

    .stepper-steps-active {
      .stepper-icon-step {
        .stepper-icon {
          background-color: rgb(145,85,253);
          .v-icon {
            color: rgba(255,255,255);
          }
        }
      }
    }

    .stepper-steps-completed {
      .stepper-icon-step {
        .stepper-icon {
          background: rgba(145,85,253, 0.08);
          .v-icon {
            color: rgba(145,85,253);
          }
        }
      }
    }
  }

  // 👉 stepper step with icon and  default
  .v-slide-group__content {
    row-gap: 1.5rem;

    .stepper-step-indicator {
      border: 0.3125rem solid rgb(145,85,253);
      border-radius: 50%;
      background-color: rgb(var(--v-theme-surface));
      block-size: 1.25rem;
      inline-size: 1.25rem;
      opacity: 0.1;
    }

    .stepper-step-line {
      border-radius: 0.1875rem;
      background-color: rgb(145,85,253);
      block-size: 0.1875rem;
      inline-size: 3.75rem;
      opacity: 0.1;
    }

    .stepper-chevron-indicator {
      color: rgba(58,53,65, 0.38);
    }

    .stepper-steps-completed,
    .stepper-steps-active {
      .stepper-icon-step,
      .stepper-step-icon {
        color: rgb(145,85,253) !important;
      }

      .stepper-step-indicator {
        opacity: 1;
      }
    }

    .stepper-steps-completed {
      .stepper-step-line {
        opacity: 1;
      }

      .stepper-chevron-indicator {
        color: rgb(145,85,253);
      }
    }

    .stepper-steps-invalid.stepper-steps-active {
      .stepper-icon-step,
      .step-number,
      .step-title,
      .step-subtitle {
        color: rgb(255,15,105) !important;
      }
    }
  }

  // 👉 stepper alignment
  &.app-stepper-center {
    .v-slide-group__content {
      justify-content: center;
    }
  }

  &.app-stepper-start {
    .v-slide-group__content {
      justify-content: start;
    }
  }

  &.app-stepper-end {
    .v-slide-group__content {
      justify-content: end;
    }
  }
}
</style>
