var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-6"},[_c('label',{staticClass:"v-label v-label--active pt-2",staticStyle:{"left":"0px","right":"auto","position":"absolute","transform":"translateY(-16px) scale(0.75)"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('tiptap-vuetify',{ref:"inputRef",staticClass:"pr-2 pt-2",attrs:{"placeholder":"Write something …","extensions":_vm.extensions,"native-extensions":_vm.nativeExtensions,"card-props":{ flat: false, outlined:true, style:_vm.borderStyle},"toolbar-attributes":{ color: 'black', dark: true }},on:{"init":_vm.onInit},scopedSlots:_vm._u([{key:"toolbar",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('header',{staticClass:"v-sheet theme--dark v-toolbar v-toolbar--dense v-toolbar--flat black"},[_c('div',{staticClass:"v-toolbar__content"},[_c('div',[_c('v-btn',{staticClass:"theme--dark",class:isActive.underline() ? 'v-btn--active' : '',attrs:{"small":"","text":"","icon":"","title":"Underline"},on:{"click":commands.underline}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatUnderline))])],1),_c('v-btn',{staticClass:"theme--dark",class:isActive.strike() ? 'v-btn--active' : '',attrs:{"small":"","text":"","icon":"","title":"Strike"},on:{"click":commands.strike}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatStrikethrough))])],1),_c('v-btn',{staticClass:"theme--dark",class:isActive.bold() ? 'v-btn--active' : '',attrs:{"small":"","text":"","icon":"","title":"Bold"},on:{"click":commands.bold}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatBold))])],1),_c('v-btn',{staticClass:"theme--dark",class:isActive.italic() ? 'v-btn--active' : '',attrs:{"small":"","text":"","icon":"","title":"Italic"},on:{"click":commands.italic}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatItalic))])],1),_c('v-btn',{staticClass:"theme--dark",class:isActive.interviewer() ? 'v-btn--active' : '',attrs:{"small":"","text":"","icon":"","title":"Interviewer Instructions"},on:{"click":commands.interviewer}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountVoice))])],1),_c('v-btn',{staticClass:"theme--dark",class:isActive.bullet_list() ? 'v-btn--active' : '',attrs:{"small":"","text":"","icon":"","title":"Bullet List"},on:{"click":commands.bullet_list}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatListBulleted))])],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"theme--dark",class:isActive.variable() ? 'v-btn--active' : '',attrs:{"small":"","text":"","icon":"","title":"Variable"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAt))])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.variableSuggestions),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return commands.variable(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-subtitle',_vm._g(_vm._b({},'v-list-item-subtitle',attrs,false),on),[_vm._v(_vm._s(item.label))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.value))])])],1)}),1)],1),_c('v-btn',{staticClass:"theme--dark",class:isActive.horizontal_rule() ? 'v-btn--active' : '',attrs:{"small":"","text":"","icon":"","title":"Horizontal Rule"},on:{"click":commands.horizontal_rule}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMinus))])],1),_c('v-btn',{staticClass:"theme--dark",attrs:{"small":"","text":"","icon":"","title":"Undo"},on:{"click":commands.undo}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiUndo))])],1),_c('v-btn',{staticClass:"theme--dark",attrs:{"small":"","text":"","icon":"","title":"Redo"},on:{"click":commands.redo}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiRedo))])],1)],1)])])]}}]),model:{value:(_vm.valueInner),callback:function ($$v) {_vm.valueInner=$$v},expression:"valueInner"}}),(!_vm.checker)?_c('div',{staticClass:"ml-4 text-xs v-messages theme--light error--text"},[_c('span',{staticClass:"text--red"},[_vm._v("This field is required")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }