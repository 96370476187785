var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Questionnaire Versions ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localVersions,"loading":_vm.localLoading,"dense":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"text-capitalize text--primary"},[_vm._v(_vm._s(_vm.dateFormat(item.created_at)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","dark":"","small":"","disabled":!_vm.$can('read', 'forsta')},on:{"click":function($event){{}}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiWrenchOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Generate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"warning","dark":"","small":"","loading":_vm.dlVersionLoading,"disabled":!_vm.$can('read', 'forsta')},on:{"click":function($event){return _vm.downloadQuestionnaireVersion(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownloadBoxOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Download JSON")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info","dark":"","small":"","loading":_vm.dlWordVersionLoading,"disabled":!_vm.$can('read', 'project')},on:{"click":function($event){return _vm.downloadWordQuestionnaireVersion(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFileWordOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Download Word")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }