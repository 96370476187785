<template>
    <v-data-table
        :headers="localHeaders"
        :items="localItems"
        class="elevation-1"
        hide-default-header
    >
        <template v-slot:top>
        <v-toolbar
            flat
        >
            <v-toolbar-title class=""></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog
            v-model="dialog"
            max-width="500px"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                small
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                >
                New Row
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                <v-container>
                    <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-for="(value, key, index) in editedItem"
                        :key="index"
                    >
                        <v-text-field
                        v-model="editedItem[key]"
                        :label="headerName(key)"
                        ></v-text-field>
                    </v-col>
                    
                    </v-row>
                </v-container>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="520px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="dialogColumnDelete" max-width="520px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this column?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeColumnDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteColumnConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-toolbar>
        </template>

        

<template  v-slot:header="{ props }">
    <thead class="v-data-table-header">
    <tr>
        <th v-for="head in props.headers" 
            :key="head.value"
            class="text-start"
            role="columnheader"
            scope="col">

            <v-menu
            v-model="headerActions"
            :close-on-content-click="false"
            offset-y
            :transition="false"
            v-if="head.value == 'actions'"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-list>
                <v-list-item v-for="item in localHeaders.filter(header => header.value !== 'actions')" :key="item.value" >
                    <!-- <v-list-item-action>
                    <v-checkbox
                        v-model="item.active"
                    />
                    </v-list-item-action> -->
                    <template v-if="item.value !== 'actions'">
                        <v-list-item-content style="cursor: default">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                                <v-icon
                                small
                                @click="deleteColumn(item)"
                                v-if="item.value !== 'name'"
                                >
                                {{icons.mdiDelete}}
                                </v-icon>
                        </v-list-item-action>
                    </template>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-dialog
                            v-model="columnDialog"
                            max-width="500px"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                small
                                color="warning"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                                >
                                New Column
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                <span class="text-h5">Add new column</span>
                                </v-card-title>

                                <v-card-text>
                                <v-container>
                                    <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-text-field
                                            v-model="defaultColumn.text"
                                            label="Label"
                                        ></v-text-field>
                                    </v-col>
                                    
                                    </v-row>
                                </v-container>
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeColumn"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveColumn"
                                >
                                    Save
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        <!-- <v-btn color="warning" small>New Column</v-btn> -->
                    </v-list-item-action>
                </v-list-item>
                </v-list>
            </v-card>
            </v-menu>

            <v-edit-dialog
                :return-value.sync="head.value"
                v-else
            >
                {{ head.text }}
                <template v-slot:input>
                <v-text-field
                    v-model="head.text"
                    label="Edit"
                    single-line
                    counter
                    @change="updateHeaders"
                ></v-text-field>
                </template>
            </v-edit-dialog>
        
        </th>

        </tr>
    </thead>
    </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
                {{icons.mdiPencil}}
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
                {{icons.mdiDelete}}
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
                Reset
            </v-btn>
        </template>
    </v-data-table>
    
</template>

<script>
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import {
    mdiDotsVertical,
    mdiPencil,
    mdiDelete
} from '@mdi/js'

export default ({
    props:{
        headers: {
            type:Array,
            default: () => [
                {
                    text: 'Question',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                    active: true
                },
                { 
                    text: 'Cell 1',
                    value: 'col_1',
                    active: true
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false
                }
            ]
        },
        items: {
            type:Array,
            default: () => [
                {
                    name: 'Flash',
                    col_1: "SKU1A",
                    col_2: "SKU1B",
                    col_3: "SKU1C",
                },
            ]
        }
    },
    setup(props, { emit }) {

        const dialog = ref(false)
        const columnDialog = ref(false)
        const dialogDelete = ref(false)
        const dialogColumnDelete = ref(false)
        const headerActions = ref(false)
        const localHeaders = ref([])
        const localItems = ref([])
        const editedItem = ref({})
        const defaultItem = ref({})
        const defaultColumn = ref({})

        const initialize = () => {
            localHeaders.value = JSON.parse(JSON.stringify(props.headers))
            localItems.value = JSON.parse(JSON.stringify(props.items))
            localHeaders.value.map(header => {
                if(header.value != 'actions') {
                    editedItem.value[header.value] = ''
                    defaultItem.value[header.value] = ''
                }
            })

            if (localHeaders.value[localHeaders.value.length-1].value != "actions"){
                localHeaders.value.push(
                {
                    text: 'Actions',
                    value: 'actions',
                    active: false,
                    sortable: false
                })

            }

        }
        const editedIndex = ref(-1)
        const headerIndex = ref(-1)
        const formTitle = computed(() => {
            return editedIndex.value === -1 ? "New Row" : "Edit Row"
        })

        const editItem = (item) => {
            editedIndex.value = localItems.value.indexOf(item)
            editedItem.value = Object.assign({}, item)
            dialog.value = true
        }

        const deleteItem = (item) => {
            editedIndex.value = localItems.value.indexOf(item)
            editedItem.value = Object.assign({}, item)
            dialogDelete.value = true
        }

        const deleteColumn = (item) => {
            headerIndex.value = localHeaders.value.indexOf(item)
            dialogColumnDelete.value = true
        }
        

        const closeColumnDelete = () => {
            dialogColumnDelete.value = false
            headerIndex.value = -1
        }


        const deleteColumnConfirm = () => {
            const item = localHeaders.value[headerIndex.value]
            localHeaders.value.splice(headerIndex.value, 1)
            localItems.value.map(element => {
                delete element[item.value]
            })
            delete editedItem.value[item.value]
            delete defaultItem.value[item.value]
            
            emit('save-headers', localHeaders.value)
            emit('save-items', localItems.value)
            closeColumnDelete()
        }
        

        const deleteItemConfirm = () => {
            localItems.value.splice(editedIndex.value, 1)
            emit('save-items', localItems.value)
            closeDelete()
        }

        const close = () => {
            dialog.value = false
            editedItem.value = Object.assign({}, defaultItem.value)
            editedIndex.value = -1
        }

        const closeDelete = () => {
            dialogDelete.value = false
            editedItem.value = Object.assign({}, defaultItem.value)
            editedIndex.value = -1
        }

        const headerName = (key) => {
            const header =  localHeaders.value.find(header => header.value == key)
            if (header != null || header != undefined) return header.text
            return 'No Name'
        }

        const save = () => {
            if (editedIndex.value > -1) {
                Object.assign(localItems.value[editedIndex.value], editedItem.value)
            } else {
                localItems.value.push(editedItem.value)
            }
            emit('save-items', localItems.value)
            close()
        }

        const closeColumn = () => {
            defaultColumn.value = {}
            columnDialog.value = false
            headerIndex.value = -1
        }
        
        const saveColumn = () => {
            let dupName = false
            let indexLabel = 1
            while(!dupName){
                defaultColumn.value.value = `col_${indexLabel}`
                if(localHeaders.value.map(a => a.value).filter(element => element == defaultColumn.value.value).length == 0){
                    dupName = true
                } else {
                    indexLabel++
                }
            }
            defaultColumn.value.active= true 
            localHeaders.value.splice(-1, 0,defaultColumn.value)
            
            localItems.value.map(element => {
                element[defaultColumn.value.value] = ''
            })
            
            editedItem.value[defaultColumn.value.value] = ''
            defaultItem.value[defaultColumn.value.value] = ''
            emit('save-headers', localHeaders.value)
            emit('save-items', localItems.value)
            closeColumn()
        }

        const updateHeaders = () => {
            emit('save-headers', localHeaders.value)
        }


        watch(
            () => [props.headers, props.items],
            () => {
                initialize()
            }
        )

        watch(
            dialog,
            (val) => {
                val || close()
            }
        )
        watch(
            dialogDelete,
            (val) => {
                val || closeDelete()
            }
        )
        watch(
            dialogColumnDelete,
            (val) => {
                val || closeColumnDelete()
            }
        )
        onMounted(() => {
            initialize()
        })
        

        return {
            headerActions,
            localHeaders,
            localItems,
            dialog,
            columnDialog,
            dialogDelete,
            dialogColumnDelete,
            closeColumnDelete,
            deleteColumnConfirm,
            formTitle,
            editedItem,
            defaultItem,

            closeColumn,
            saveColumn,
            defaultColumn,

            // Functions
            editItem,
            deleteItem,
            deleteItemConfirm,
            deleteColumn,
            close,
            closeDelete,
            save,
            headerName,
            updateHeaders,
            initialize,
            
            // Icons
            icons: {
                mdiDotsVertical,
                mdiPencil,
                mdiDelete
            },

        }
        
    },
})
</script>
