<template>
    <v-card
        flat
    >
        <v-card-title>
            Questionnaire Versions
        </v-card-title>

        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="localVersions"
                :loading="localLoading"
                dense
            >

                <!-- Service Type -->
                <template #[`item.created_at`]="{item}">
                    <div class="d-flex flex-column ms-3">
                        <span class="text-capitalize text--primary">{{ dateFormat(item.created_at)}}</span>
                    </div>
                </template>

                <template #[`item.actions`]="{item}" >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="{}"
                                :disabled="!$can('read', 'forsta')"
                            >
                            {{ icons.mdiWrenchOutline }}
                            </v-icon>
                        </template>
                        <span>Generate</span>
                    </v-tooltip>
                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                :loading="dlVersionLoading"
                                @click="downloadQuestionnaireVersion(item.id)"
                                :disabled="!$can('read', 'forsta')"
                            >
                            {{ icons.mdiDownloadBoxOutline }}
                            </v-icon>
                        </template>
                        <span>Download JSON</span>
                    </v-tooltip>
                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="info"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                small
                                :loading="dlWordVersionLoading"
                                @click="downloadWordQuestionnaireVersion(item.id)"
                                :disabled="!$can('read', 'project')"
                            >
                            {{ icons.mdiFileWordOutline }}
                            </v-icon>
                        </template>
                        <span>Download Word</span>
                    </v-tooltip>
                    
                </template>
                
            </v-data-table>

        </v-card-text>

    </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import useSurvey from '../useSurvey'

import { 
    mdiWrenchOutline,
    mdiDownloadBoxOutline,
    mdiFileWordOutline
 } from '@mdi/js'

export default ({
    props: {
        versions: {
            type:Array
        },
        loading: {
            type:Boolean
        }
    },
    setup(props) {

        const headers = ref([
            {
                text: 'Verions',
                align: 'start',
                value: 'version',
            },
            { text: 'Date', value: 'created_at' },
            { text: 'Validated By', value: 'created_by' },
            { text: 'Decipher Path', value: 'path' },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false
            }
        ])

        const localVersions = ref([])
        const localLoading = ref(false)

        const {
            dlVersionLoading,
            dlWordVersionLoading,
            downloadQuestionnaireVersion,
            downloadWordQuestionnaireVersion
        } = useSurvey()

        const dateFormat = (date) => {
            const d = new Date(date)
            const newDate = d.toISOString().split('T')[0];
            const time = d.toTimeString().split(' ')[0];
            return `${newDate} ${time}`
            
        }

        watch(
            () => props.versions,
            () => {
                localVersions.value = JSON.parse(JSON.stringify(props.versions))
            }
        )
        watch(
            () => props.loading,
            () => {
                localLoading.value = props.loading
            }
        )


        return {
            headers,
            localVersions,
            localLoading,
            dlVersionLoading,
            dlWordVersionLoading,
            downloadQuestionnaireVersion,
            downloadWordQuestionnaireVersion,
            dateFormat,

            icons: {
                mdiWrenchOutline,
                mdiDownloadBoxOutline,
                mdiFileWordOutline
            }
        }
        
    },
})
</script>
